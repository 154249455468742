<template>
  <base-material-card
    color="primary"
    icon="mdi-cog"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">Configurações Extras</div>
    </template>
    <v-form class="pt-5">
      <v-row
        align="center"
        no-gutters
        class="ml-4"
      >
        <v-col cols="12">
          <v-checkbox
            class="pt-0 mt-0"
            v-model="backupEntityLocal"
            :label="backupEntityLabel"
          />
        </v-col>
      </v-row>
      <v-row
        align="center"
        no-gutters
        class="ml-4"
      >
        <v-col cols="12">
          <v-radio-group
            v-model="tipoComandoLocal"
            row
            class="pt-0 mt-0"
          >
            <v-radio
              label="Gerar UPDATE"
              value="UPDATE"
            />
            <v-radio
              label="Gerar DELETE"
              value="DELETE"
            />
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
  </base-material-card>
</template>

<script>
export default {
  props: {
    backupEntity: {
      type: Boolean,
      default: false
    },
    tipoComando: {
      type: String,
      default: 'UPDATE'
    }
  },
  computed: {
    backupEntityLocal: {
      get() {
        return this.backupEntity;
      },
      set(newValue) {
        this.$emit('update:backupEntity', newValue);
      }
    },
    tipoComandoLocal: {
      get() {
        return this.tipoComando;
      },
      set(newValue) {
        this.$emit('update:tipoComando', newValue);
      }
    },
    backupEntityLabel() {
      let acao = this.tipoComandoLocal === 'DELETE' ? 'excluir' : 'atualizar';
      return `Efetuar backup da entidade antes de ${acao} os dados.`;
    }
  }
};
</script>
